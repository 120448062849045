import React, { useState, useEffect } from 'react';

import { AnimatePresence } from 'framer-motion';
import { Text } from 'atoms/Text';
import * as SI from './css';

export interface ScrollIndicatorProps {
    hasScrolled: boolean;
}

const ANIMATION = {
    initial: { x: [50, 0], opacity: [0, 1], transition: { delay: 3.25 } },
    default: { x: [-100, 0], opacity: [0, 1], transition: { delay: 0.5 } },
    exit: { x: [0, -100], opacity: [1, 0], transition: { delay: 0 } }
};

const ANIMATION_BAR = {
    initial: { x: [50, 0], opacity: [0, 1], transition: { delay: 3.75 } },
    default: { x: [-100, 0], opacity: [0, 1], transition: { delay: 0.1 } },
    exit: { x: [0, -100], opacity: [1, 0], transition: { delay: 0.25 } }
};

export const ScrollIndicator: React.SFC<ScrollIndicatorProps> = ({ hasScrolled }) => {
    const [scrolledTimes, setScrolledTimes] = useState<number>(0);

    useEffect(() => {
        if (hasScrolled) {
            setScrolledTimes(value => value + 1);
        }
    }, [hasScrolled, setScrolledTimes]);

    return (
        <AnimatePresence initial={true}>
            {!hasScrolled && (
                <SI.ScrollIndicator animate={{ opacity: [0, 1] }} transition={{ duration: 0 }}>
                    <SI.ScrollIndicator__Label
                        animate={scrolledTimes > 0 ? 'default' : 'initial'}
                        exit={'exit'}
                        variants={ANIMATION}
                    >
                        <Text type={'span'} modifiers={['xSmall', 'uppercase', 'mono']}>
                            Scroll
                        </Text>
                    </SI.ScrollIndicator__Label>
                    <SI.ScrollIndicator__Bar
                        animate={scrolledTimes > 0 ? 'default' : 'initial'}
                        exit={'exit'}
                        variants={ANIMATION_BAR}
                    >
                        <SI.ScrollIndicator__Index
                            animate={{ x: [100, -100] }}
                            transition={{ duration: 2, repeat: Infinity }}
                        />
                    </SI.ScrollIndicator__Bar>
                </SI.ScrollIndicator>
            )}
        </AnimatePresence>
    );
};
