import styled from 'styled-components';
import { motion as Motion } from 'framer-motion';
import { down } from 'styled-breakpoints';

import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

export const ScrollIndicator__Bar = styled(Motion.span)`
    position: relative;
    display: block;
    margin-left: ${Gaps.m};
    width: ${PxToRem(75)};
    height: ${PxToRem(1)};
    opacity: 0;
    background-color: transparent;
    overflow: hidden;

    ${down('sm')} {
        width: ${PxToRem(64)};
        height: ${PxToRem(1)};
    }
`;
