import styled from 'styled-components';

export const Hero__Teaser = styled.div`
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin: 0;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    opacity: 0.04;

    & p {
        line-height: 1;
    }
`;
