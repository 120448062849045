import styled from 'styled-components';
import { rgba, darken } from 'polished';
import { up } from 'styled-breakpoints';
import { motion as Motion } from 'framer-motion';

import { PxToRem } from 'common/functions/PxToRem';
import { Colors } from 'common/settings/Colors';

export const Hero = styled(Motion.div)`
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: ${props => props.theme.colors.background.I};
    transition: background-color 0.05s ease-in;
`;

export const HeroCover = styled(Motion.div)`
    position: fixed;
    z-index: 5;
    left: 0;
    top: 100%;
    width: 100vw;
    height: 100vh;
    background-color: ${darken(1, Colors.greyDark)};
    transition: background-color 0.05s ease-in-out;
`;

export const HeroMirror = styled.div`
    width: 100vw;
    height: 75vh;
`;
