import React, { useEffect, useLayoutEffect, useCallback, useState, useContext } from 'react';
import { useViewportScroll, useTransform } from 'framer-motion';
import ScrollLock from 'react-scrolllock';

import { ThemeContext } from '../../context/ThemeState';

import { Grid, Grid__Item } from 'components/Grid';
import { ScrollIndicator } from 'components/ScrollIndicator';
import { Smiley } from 'components/Smiley';
import { Button } from 'atoms/Button';
import { Text } from 'atoms/Text';

import * as HE from './css';
import * as TXT from 'atoms/Text/css';

export interface HeroProps {
    hero: {
        headlineI: string;
        headlineII: string;
        skills: string[];
        slogan: string;
    };
}

export const Hero: React.FC<HeroProps> = ({ hero: { headlineI, headlineII, skills, slogan } }) => {
    const { changeTheme } = useContext(ThemeContext);
    const { scrollYProgress } = useViewportScroll();
    const value = useTransform(scrollYProgress, value => 1 - value * 5);
    const [scrollLock, setScrollLock] = useState<boolean>(true);

    const [hasScrolled, setHasScrolled] = useState<boolean>(false);
    const [isLocked, setIsLocked] = useState<boolean>(true);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [windowMeasures, setWindowMeasures] = useState<{ width: number; height: number }>({
        width: typeof window !== `undefined` ? window.innerWidth : 0,
        height: typeof window !== `undefined` ? window.innerHeight : 0
    });

    useEffect(() => {
        const scrollListener = (e: Event) => {
            if (typeof window !== `undefined`) {
                setScrollPosition(window.pageYOffset * 1.5);
                if (window.pageYOffset > windowMeasures.height * 0.6 && scrollLock) {
                    setScrollLock(false);
                    changeTheme(false);
                }

                if (window.pageYOffset < windowMeasures.height * 0.6 && !scrollLock) {
                    setScrollLock(true);
                    changeTheme(true);
                }

                if (window.pageYOffset > 10 && !hasScrolled) {
                    setHasScrolled(true);
                }

                if (window.pageYOffset <= 10 && hasScrolled) {
                    setHasScrolled(false);
                }
            }
        };

        const getWindowMeasures = () =>
            setWindowMeasures(() => {
                return { width: window.innerWidth, height: window.innerHeight };
            });

        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', scrollListener);
            window.addEventListener('resize', getWindowMeasures);

            return () => {
                window.removeEventListener('scroll', scrollListener),
                    window.removeEventListener('resize', getWindowMeasures);
            };
        }
    });

    useEffect(() => {
        changeTheme(true);
    }, []);

    useEffect(() => {
        if (typeof window !== `undefined` && window.pageYOffset > 10) {
            setIsLocked(false);
        } else {
            setTimeout(() => setIsLocked(false), 3500);
        }
    });

    return (
        <>
            {/* <div style={{ position: 'fixed', top: 0, zIndex: 10 }}>
                {window.pageYOffset + ',' + windowMeasures.height}
            </div> */}
            <HE.Hero style={{ y: `-${scrollPosition}px` }}>
                <ScrollIndicator hasScrolled={hasScrolled} />
                <HE.Hero__Header>
                    <Grid modifiers={['alignCenter']}>
                        <Grid__Item
                            responsive={{
                                md: { start: 2, span: 9 },
                                xxxl: { start: 3, span: 8 }
                            }}
                        >
                            <Text
                                type={'h1'}
                                modifiers={[
                                    'headline',
                                    'spacingTop',
                                    'spacingBottom',
                                    'lineHeightHeadline',
                                    'weight600'
                                ]}
                                spacingTop={'xxl'}
                                spacingBottom={'m'}
                            >
                                <Text type={'span'} modifiers={['underline']}>
                                    <TXT.Text__Span
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: [0, 1] }}
                                        transition={{ delay: 1.3, duration: 0.5 }}
                                    >
                                        {headlineI}
                                    </TXT.Text__Span>
                                </Text>
                                &nbsp;
                                <Text type={'span'} modifiers={['weight300']}>
                                    <TXT.Text__Span
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: [0, 1] }}
                                        transition={{ delay: 2, duration: 0.5 }}
                                    >
                                        {headlineII}
                                    </TXT.Text__Span>
                                </Text>
                            </Text>
                            <Text
                                type={'p'}
                                modifiers={['small', 'weight500', 'uppercase', 'spacingTop']}
                                spacingTop={'l'}
                            >
                                <TXT.Text__Span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: [0, 1], x: [-100, 0] }}
                                    transition={{ delay: 3, duration: 0.5 }}
                                >
                                    {skills.map((skill: string, index: number) => (
                                        <React.Fragment key={index}>
                                            <Text key={index} type={'span'} modifiers={['mono', 'weight400']}>
                                                {skill}
                                            </Text>
                                            {index < skills.length - 1 && (
                                                <Text
                                                    type={'span'}
                                                    modifiers={['spacingLeft', 'spacingRight']}
                                                    spacingLeft={'s'}
                                                    spacingRight={'s'}
                                                >
                                                    ×
                                                </Text>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TXT.Text__Span>
                            </Text>
                        </Grid__Item>
                    </Grid>
                </HE.Hero__Header>
                {/* <Smiley /> */}
            </HE.Hero>
            {scrollLock && (
                <HE.HeroCover style={{ top: windowMeasures.height, y: `-${scrollPosition}px`, opacity: value }} />
            )}
            <HE.HeroMirror />
            <ScrollLock isActive={isLocked} />
        </>
    );
};
