import styled from 'styled-components';
import { motion as Motion } from 'framer-motion';
import { down } from 'styled-breakpoints';

import { Gaps } from 'common/settings/Gaps';

export const ScrollIndicator = styled(Motion.div)`
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    bottom: ${Gaps.xxl};
    padding: 0 0 0 0;
    opacity: 0;
    transform: rotate(90deg) translateX(-50%) translateY(100%);
    transform-origin: bottom;

    ${down('sm')} {
        left: initial;
        right: -${Gaps.xxl};
        bottom: ${Gaps.xxxxl};
    }
`;
