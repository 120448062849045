import styled from 'styled-components';
import { motion as Motion } from 'framer-motion';

export const ScrollIndicator__Index = styled(Motion.span)`
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: ${props => props.theme.colors.foreground.I};
    transform: translateX(50%);
`;
