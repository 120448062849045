import React from 'react';
import { graphql } from 'gatsby';

import { Main } from '../layouts/Main';
import { Footer } from 'components/Footer';
import { Hero } from 'components/Hero';
import { PageBuilder } from 'components/PageBuilder';

export interface I_IndexPage {
    data: {
        site: any;
        contentJson: any;
    };
}

const IndexPage = ({ data: { contentJson } }: I_IndexPage) => {
    const { description, keywords, siteUrl, hero, body, title, imageSocialShare } = contentJson;

    return (
        <>
            <Main seoProps={{ title, description, keywords, siteUrl, imageSocialShare, canonical: siteUrl }}>
                {/* <Cursor /> */}
                <Hero hero={hero} />
                <PageBuilder body={body} />
                <Footer />
            </Main>
        </>
    );
};

export default IndexPage;

export const data = graphql`
    query {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                defaultKeywords: keywords
                siteUrl
            }
        }
        contentJson(page: { eq: "index" }) {
            imageSocialShare
            title
            description
            keywords
            siteUrl
            hero {
                headlineI
                headlineII
                skills
                slogan
            }
            body {
                type
                modifiers
                gridWrapper {
                    modifiers
                    spacingTop
                    spacingBottom
                }
                gridWrapperItems {
                    responsive
                }
                headline {
                    atom
                    content
                    modifiers
                    spacingTop
                    spacingBottom
                    type
                    children {
                        atom
                        content
                        modifiers
                        type
                    }
                }
                text {
                    atom
                    content
                    modifiers
                    link
                    spacingTop
                    spacingBottom
                    type
                    children {
                        atom
                        content
                        modifiers
                        spacingBottom
                        type
                    }
                }
                items {
                    responsive
                    modifiers
                    content {
                        shortTitle
                        title
                        type
                    }
                }
                points {
                    content
                }
            }
        }
    }
`;
